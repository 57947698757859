@import "../colors";

.ListItemButtonCustom {
  width: 100%;
}

.ListItemIconCustom {
  min-width: auto !important;
  width: 100%;
  justify-content: center;
  color: $lightGray !important;
}

.active .ListItemIconCustom {
  color: $darkGray !important;
}
