@import "colors";

// Components

@import "components/sidebar";


.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 892px;
  max-height: 95vh;
  overflow: auto;
  background-color: white;
  border: none;
  border-radius: 4px;

  small {
    font-weight: 300;
    font-size: .75rem;
  }
}
